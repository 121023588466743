mat-sidenav {
    width: 100px;
    padding-top: 0px;
}

::-webkit-scrollbar {
  width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}
   
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8897A9ed;
  border-radius: 10px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8897A9ed;
}
