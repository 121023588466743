
 @import 'variable';
  @import 'spinner';
  @import 'header';
 @import 'pages';
 @import 'sidebar';
 @import 'responsive';
 
 @import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";
 @font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/MontRegular.eot');
  src: url('../../assets/fonts/MontRegular.eot') format('embedded-opentype'),
       url('../../assets/fonts/MontRegular.woff2') format('woff2'),
       url('../../assets/fonts/MontRegular.woff') format('woff'),
       url('../../assets/fonts/MontRegular.ttf') format('truetype'),
       url('../../assets/fonts/MontRegular.svg#MontRegular') format('svg');
}
// SEMI BOLD
@font-face {
  font-family: 'MontSemiBold';
  src: url('../../assets/fonts/MontSemiBold.eot');
  src: url('../../assets/fonts/MontSemiBold.eot') format('embedded-opentype'),
       url('../../assets/fonts/MontSemiBold.woff2') format('woff2'),
       url('../../assets/fonts/MontSemiBold.woff') format('woff'),
       url('../../assets/fonts/MontSemiBold.ttf') format('truetype'),
       url('../../assets/fonts/MontSemiBold.svg#MontSemiBold') format('svg');
}
// SEMI BOLD

// BOLD
@font-face {
  font-family: 'MontBold';
  src: url('../../assets/fonts/MontBold.eot');
  src: url('../../assets/fonts/MontBold.eot') format('embedded-opentype'),
       url('../../assets/fonts/MontBold.woff2') format('woff2'),
       url('../../assets/fonts/MontBold.woff') format('woff'),
       url('../../assets/fonts/MontBold.ttf') format('truetype'),
       url('../../assets/fonts/MontBold.svg#MontBold') format('svg');
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../../assets/fonts/proxima-nova/proximanova_regular.ttf');
  src: url('../../assets/fonts/proxima-nova/proximanova_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('../../assets/fonts/proxima-nova/proximanova_bold.otf');
  src: url('../../assets/fonts/proxima-nova/proximanova_bold.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNovaLight';
  src: url('../../assets/fonts/proxima-nova/proximanova_light.otf');
  src: url('../../assets/fonts/proxima-nova/proximanova_light.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNovaExtraBold';
  src: url('../../assets/fonts/proxima-nova/proximanova_extrabold.otf');
  src: url('../../assets/fonts/proxima-nova/proximanova_extrabold.otf') format('opentype');
}

p.welcome_ {
  font-family: 'Poppins';
}

 body{
	margin: 0;
  font-family: 'ProximaNova';
  overflow: auto !important;

}
*,:focus{
	outline: none;
}
*{
	margin: 0;
	padding: 0;
}
p,h1,h2,h3,h4,h5,h6,ul,li{
	margin: 0;
	padding: 0;
}

a:hover{
	text-decoration: none;
}

.success {
    background: $primary_btn !important ;
    color:white;
  }
  .failure {
    background: $primary_btn !important ;
    color: white;
    
  }

  .mat-snack-bar-container {
    margin-top: 68px;
    margin-right: 36px;
  }

  .mat-drawer-inner-container {
    color:#777777;
     background-color: white;
  }

  .cropper {
    position: fixed;
    width: 100%;
    height:100vh;
    top: 0px;
    left: 0px;
    background: #fff;
    z-index: 9999;
    text-align: center;

    .cropper-title {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 14px;
    }
}
.cropper_outer {
  background-color: #fdfdfd;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
}

.cropperImg{
  max-height: 80vh !important;
}

.total_label {
  font-size: 12px;
  color: #777777;
}
._left_margin {
  margin: 0;
  padding: 0;
}

.full_name {
  font-size: 24px;
  color: #000000;
  font-weight: 500;
}
.tag_Line {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}
.desciption_bio {
  color: #777777;
  font-weight: 400;
  font-size: 16px;
  height: 24px;
  overflow: hidden;

}
.img_lock {
  width: 26px;
  line-height: 0;
  align-content: space-around;
  margin-bottom: 4px;
}
.class_login_text {
  margin-right: 41px;
}

img.scroll_img {
  width: 20px;
  height: 20px;
  margin-top: -10px;
}
._modal_text {
  text-align: center;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  width: 100% !important;
}

.spinner-border {
  animation-duration: 1.5s;
}
.carderror {
  font-size: 9px;
}
.mat-expansion-panel-header.test_mode:not([aria-disabled=true]) {
  cursor: pointer;
  padding-left: 0 !important;
}

.mat-menu-content {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 11px !important;

}
.mat-menu-content button :hover {
  background-color: #F7F4FE !important;
  color:#5339DC !important;
}
.mat-menu-item span {
  font-size: 11px !important;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container { z-index: 9999!important; }

.mat-menu-panel {
  min-height: 48px !important;
}