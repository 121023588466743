@use '@angular/material' as mat;
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';
@import "./assets/styles/variable.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '@angular/material/theming';

$snap-primary-palette: (
        50: #f5f5f5,
        100: #e9e9e9,
        200: #d9d9d9,
        300: #c4c4c4,
        400: #9d9d9d,
        500: #7b7b7b,
        600: #555555,
        700: #434343,
        800: #262626,
        900: #5239DC,
        A100: #ffffff,
        A200: #eeeeee,
        A400: #bdbdbd,
        A700: #303030,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: rgba(black, 0.87),
                600: white,
                700: white,
                800: white,
                900: white,
                A100: rgba(black, 0.87),
                A200: rgba(black, 0.87),
                A400: rgba(black, 0.87),
                A700: white,
        )
);
@include mat.core();

$primary: mat.define-palette($snap-primary-palette, 900);
$accent: mat.define-palette($snap-primary-palette, A700);
$warn: mat.define-palette($snap-primary-palette, A100);
$theme: mat.define-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

$custom-typography: mat.define-typography-config(
        $font-family: 'Poppins, sans-serif'
);

// Override the typography in the core CSS.
@include mat.core($custom-typography);

// Default Theme
@include angular-material-theme($theme);

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #3b5362 !important;
}
::-webkit-scrollbar {
  height: 3px;
}
::-webkit-scrollbar-thumb {
  background: $theme_color !important ;
  border-radius: 10px;
}

:focus {
    outline: 0 !important;
}
.hasError, .has-error {
  position: relative;

  input {
    border-color: red;
  }
}

.errorinput {
  font-size: 10px;
  position: absolute;
  color: $error;
  font-weight: 400;
}

.errorinput:first-letter {
    text-transform: capitalize;
}

.cursor_point{
  cursor: pointer;
}
.text_capital{
  text-transform: capitalize;
}

//table scss

.card-header-primary {
  border-radius: 3px;
  padding: 15px;
  margin:0px;
  background: $btn_color;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px $btn_color;
}

.card-header-primary-grey {
  background: #FBFBFC;
}

.card-header {
  background:  $btn_color;
  font-weight: 500;
  p {
      font-size: 15px;
      margin-bottom:0;
  }
  h4 {
     font-size: 20px;
  }
}
.card-header-dashboard {
  font-weight: 500;
  //background: linear-gradient(178deg, #c9baf4 0%, #5E2CED 100%);
  background: linear-gradient(180deg, #c9baf4 0%, #5E2CED 32%);
  padding: 10px;
  color: white;
  border-radius: 10px;
  box-shadow: 0 2px 44px 0 rgba(0,0,0,0.20);
}
mat-expansion-panel.dashboard_header.mat-expansion-panel {
    color: white;
    margin-top: 10px;
    border-radius: 10px !important;
    cursor: pointer;
}
mat-expansion-panel.dashboard_headeractive.mat-expansion-panel {
  background: linear-gradient(280deg, #A485FD 0%, #5E2CED 100%) !important;
  color: white;
  margin-top: 10px;
  border-radius: 10px !important;
}

.add_card {
  background: linear-gradient(270deg, #5239DC 0%, #9D38A4 100%);
  color: white;
  padding: 8px 30px 5px 30px;
  font-size: 14px;
  border-radius: 3px;
  border: none;
}

.control_btn_new {
  background: $theme_color;
  color: white;
  font-size: 10px;
  border-radius: 3px;
  cursor: pointer !important;
  border: none;
  margin: 5px;
}
.control_btn_new:hover{
  color: white;
}
.add_card:hover {
  color:white;
}
.add_card_new {
  background: #5e2ced;
  color: white;
  padding: 7px 22px 5px 22px;
  font-size: 14px;
  border-radius: 7px;
  font-weight: 500;
}

.add_card_new:hover {
  color:white;
}
.no_device {
  font-size: 14px;
  color: #777777;
  text-align: center;
  font-weight: 400;
  padding: 15px;
}
.no_history {
  font-size: 16px;
  color:#777777;
  text-align: center;
  font-weight: 400;
  padding:50px;
}
.no_history-no-padding {
  padding: 0;
}

.card-body {
  padding: .9375rem 20px;
  position: relative;
}
.tableheading {
color: #121212;
font-size: 15px;
font-weight: bold;
}
.table thead th {
  padding: 6px;
  border-bottom:none!important;
  border-top:none;
  background-color: rgba(99, 99, 99, 0.05) !important;
  font-size: 11px !important;
  font-weight: bold;
  font-family: 'ProximaNovaBold';
}
.table>tbody>tr>td {
  color:#201f21;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: 'ProximaNovaBold';
}
.tablename {
  font-size: 15px!important;
  color:white;
  font-weight: 500;
}
.tablesubname {
  font-size: 12px!important;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: #F5F9FF; // Choose your own color here
 }
@media(max-width:768px ){
  .btn {
      padding:9px;
      font-size: 13px;
  }
  .pagination_custom li {
      padding:7px
  }
  .card_number {
    font-size: 11px!important;
  }
}

// PAGINATION //
.pagination_custom li {
  list-style: none;
 text-decoration: none;
 display: inline-block;
 padding: 5px;
 background-color: $primary_color;
 margin: 5px;
 color: white;
 border-radius: 3px;
 cursor: pointer;
 height: 27px;
 font-size: 12px;
}
.pagination_custom ul {
  text-decoration: none;
  list-style: none;
}
.pagination_custom {
  float: right;
  font-size: 12px;
}
.disabled_pagination {
  background-color: #9f8ff5 !important;
  pointer-events: none;
  cursor: not-allowed;
}
.pagLimitSet_selecet {
  background-color: $primary_color;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  padding: 3.5px;
}
.loginBtn {
  background-image: linear-gradient(
270deg, #5239DC 0%, #9D38A4 100%);
  border: none;
  outline: none;
  color: #fafafa;
  padding: 9px 40px 6px 40px;
  border-radius: 5px;
  font-size: 12px;
}
.loginBtnSmall {
  background-image: linear-gradient(270deg, #5239DC 0%, #9D38A4 100%);
  border: none;
  outline: none;
  color: #fafafa;
  padding: 9px 16px 6px 16px;
  border-radius: 5px;
  font-size: 10px;
}
.loginBtn:hover,.loginBtnSmall:hover {
  color: #fafafa;
}
.loginBtn1 {
  background-color: #000000;
  border: none;
  outline: none;
  color: white;
  padding: 9px 40px 6px 40px;
  border-radius: 5px;
  font-size: 15px;
}
.loginBtn1:hover {
  color: white;
}
.table_outer {
  margin-bottom: 10px;
  background: #FFFFFF;
    padding: 20px 15px;
    border: 1px solid #EEEEEE;
    border-radius: 24px;
}
.border_less_table {
  background: #FFFFFF;
  border:1px solid #EEEEEE;
}
.search_btn {
  background: #777777;
    padding: 0px 15px;
    color: white;
    font-weight: 500;
    border-radius: 3px;
}

.clear_btn {
  padding: 0px 15px;
    color: #515151;
    background-color: #FFFFFF;
    font-weight: 500;
    border:1px solid #515151;
    border-radius: 3px;
}
._noWrap{
  white-space: nowrap;
  text-align: center;
}

.table .smartTable > tbody > tr > td {
  color: #333333;
  font-size: 16px;
  font-weight: 400;

}
.smartTable thead th{
  font-weight: 600 !important;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

.smartTable.tr {
  font-size: 12px;
}

.cancel_btn {
  border: 1px solid #979797;
  color: #777777;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  padding: 3px 13px 0px 13px;
}
.cancel_btn_modal {
  color: white;
    background: red;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 30px;
    position: absolute;
    right: 9px;
    top: 11px;
}
.semi_bold {
  font-family: $semibold_font;
}
.bold {
  font-family: $bold_font;
}
.letter_password {
  font-size: 10px;
  color: red;
  display: block;
}

._serial {
  font-size: 11px !important;
}
.otp-input{
  width: 40px !important;
  height: 40px !important;
  font-size: 24px;
}

.multiselect-dropdown .dropdown-btn .selected-item{
  border: 1px solid $theme_color !important;
  margin-right: 4px;
  background: $theme_color !important;
  padding: 5px 7px;
  border-radius: 2px;
  float: left;
  color: #fff;
}
.form_multiselect .multiselect-dropdown .dropdown-btn {
  background: $theme_color!important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
    background: $theme_color !important;
}
select {
  text-transform:capitalize !important;
}

.company_logo2{
  width: 150px;
}

.browser_text {
  font-size: 13px;
  text-align: center;
  color: white;
  font-weight: bolder;
  padding: 3px;
}
.dropdown-list li {
  font-size: 14px !important;
}
.ios-debug-multiselect .dropdown-list li {
  font-size: 12px !important;
}
.mat-expansion-panel-body {
  font-family:  $font_family !important;
}
.spinner-grow {
  background-color: #a485f8;
  width: 100px;
  height: 100px;
}
.current_ .mat-expansion-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:30px 13px !important;
  border-radius: inherit;
}
.endDate {
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 0;
}

._breakSpaceAlert {
  width: 90px;
  white-space: break-spaces;
}
::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $theme_color !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $theme_color!important;   /*inner circle color change*/
}

::ng-deep.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
 border-color:$theme_color!important; /*outer ring color change*/
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px !important;
  color: #201F21 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}
.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  display: block;
  height: 40px !important;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 0;
}

.terminal-outer{
  height: 170px !important;
  width: 100% !important;
}
.pac-container {
  z-index: 10000 !important;
}
input#mat-input-0 {
  font-size: 12px !important;
}

.modal-title{
  font-size: 16px !important;
  font-weight: 700 !important;
}
.inner_table_pad {
  padding: 45px 36px;
}
.inner_table_head {
    font-size: 12px;
    background: #FBFBFC;
}

.device_table{
  border:1px solid #E6E6E6;
  border-radius: 0 !important;
}

.device_table thead{
  background-color: #ececec;
  font-size: 10px;
}

.device_table tbody>tr>td{
  font-size: 11px;
  color:$label_color;
  text-align: left;
}

.device_table thead>tr>th{
  font-size: 11px;
  color:$label_color;
  text-align: left;
  padding: 14px 12px;
}
.create_title{
  color: $primary_color;
  font-size: 12px;
  font-weight: 600;
}
.admin_head {
  border-bottom: 0.5px solid #AAAAAA;
  padding-left: 5px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #8EBD5A !important
}
.tooltip1, .tooltip2, .tooltip3, .tooltipryt,
.tooltip4, .tooltip5, .tooltip6, .tooltip7,
.tooltip8, .tooltip9, .tooltip10, .tooltip11 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext, .tooltip1 .tooltiptext2,
.tooltipryt .tooltiptext, .tooltip2 .tooltiptext,
.tooltip3 .tooltiptext, .tooltip4 .tooltiptext,
.tooltip5 .tooltiptext, .tooltip6 .tooltiptext,
.tooltip7 .tooltiptext, .tooltip8 .tooltiptext1,
.tooltip8 .tooltiptext2, .tooltip9 .tooltiptext,
.tooltip10 .tooltiptext, .tooltip11 .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #ffffff !important;
  color: #000000;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 1px 1px 5px 4px #e5e5e5a1;
  z-index: 1;
  opacity: 0;
  font-weight: 500 !important;
  transition: opacity 0.6s;
  height: auto !important;
}

.wider-tt .tooltiptext, .wider-tt .tooltiptext1,
.wider-tt .tooltiptext2 {
  width: 200px !important;
}

.wider-tt.tooltip7 .tooltiptext {
  margin-left: -180px !important;
}

.left-aligned .tooltiptext, .left-aligned .tooltiptext1,
.left-aligned .tooltiptext2 {
  text-align: left !important;
}

.tooltip4 .tooltiptext {
  top: 0;
  left: 50%;
  margin-left: -135px;
  font-size: 10px;
}
.tooltip_directory .tooltiptext {
  width:100% !important
}

.tooltip6 .tooltiptext {
  top: 0;
  left: 50%;
  margin-left: -215px;
  font-size: 10px;
}

.tooltip5 .tooltiptext {
  top: 115%;
  left: 20%;
  margin-left: -90px;
  font-size: 10px;
}

.tooltip1 .tooltiptext {
  bottom: 125%;
  left: 32%;
  margin-left: -60px;
  white-space: break-spaces;
}

.tooltip1 .tooltiptext2 {
  bottom: 125%;
  right: 0;
  margin-left: -60px;
}

.tooltipryt .tooltiptext {
  top: -5px;
  left: 125%;
  margin-left: -40px;
  font-size: 12px;
  line-height: 18px;
}

.tooltip2 .tooltiptext {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  white-space: break-spaces;
}

.tooltip3 .tooltiptext {
  top: 135%;
  left: 50%;
  margin-left: -60px;
  font-size: 10px;
  white-space: normal !important;
}

.tooltip7 .tooltiptext {
  top: 135%;
  margin-left: -50px;
  font-size: 10px;
  white-space: normal !important;
  width: 70px;
}

.tooltip8 .tooltiptext1 {
  bottom: 10%;
  margin-left: 20px;
  font-size: 10px;
  white-space: normal !important;
  width: 60px;
}

.tooltip8 .tooltiptext2 {
  top: 0;
  margin-left: 20px;
  font-size: 10px;
  white-space: normal !important;
  width: 70px;
}

.tooltip9 .tooltiptext {
  top: -10%;
  margin-left: -250px;
  font-size: 10px;
  white-space: normal !important;
}

.tooltip10 .tooltiptext {
  top: 135%;
  margin-left: 0;
  font-size: 10px;
  word-wrap: break-word;
  width: 120px;
}

.tooltip11 .tooltiptext {
  top: 135%;
  margin-left: -40px;
  font-size: 10px;
  word-wrap: break-word;
  width: 100px;
}

.tooltip3.airplane .tooltiptext {
  left: -1% !important;
}

.tooltiptext::after, .tooltiptext1::after, .tooltiptext2::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 5px;
  border-color: transparent #fff transparent transparent;
  margin-top: -5px;
}

.tooltip1 .tooltiptext1::after {
    top: 100%;
    left: 50%;
}
.tooltip1 .tooltiptext::after {
    top: 100%;
    left: 50%;
}
.tooltip1 .tooltiptext2::after {
    top: 100%;
    left: 50%;
}
.tooltipryt .tooltiptext::after {
    top: 50%;
    right: 100%;
}
.tooltip2 .tooltiptext::after {
    top: 100%;
    left: 50%;
}
.tooltip3 .tooltiptext::after {
    bottom: 100%;
    left: 50%;
}

.tooltip7 .tooltiptext::after {
  bottom: 100%;
  left: 50%;
}

.tooltip8 .tooltiptext1::after, .tooltip8 .tooltiptext2::after {
  bottom: 100%;
  left: 50%;
}

.tooltip9 .tooltiptext::after {
  bottom: 100%;
  left: 50%;
}

.tooltipryt:hover .tooltiptext, .tooltip2:hover .tooltiptext,
.tooltip3:hover .tooltiptext, .tooltip1:hover .tooltiptext2,
.tooltip1:hover .tooltiptext, .tooltip4:hover .tooltiptext,
.tooltip5:hover .tooltiptext, .tooltip6:hover .tooltiptext,
.tooltip7:hover .tooltiptext, .tooltip8:hover .tooltiptext1,
.tooltip8:hover .tooltiptext2, .tooltip9:hover .tooltiptext,
.tooltip10:hover .tooltiptext, .tooltip11:hover .tooltiptext{
  visibility: visible;
  opacity: 1 !important;
}

snack-bar-container.mat-snack-bar-container.danger.mat-snack-bar-top {
  background-color: $error;
  color: white;
}
.mat-snack-bar-container{
  padding: 8px 16px !important;
}
.login_input_signIn {
  background: white;
  padding: 20px;
  font-size: 14px;
  border-radius: 6px;
  padding-left: 17px;
  border: 1.5px solid #dadadb;
  }
  .login_input {
    background: white;
    padding: 10px;
    font-size: 14px;
    border-radius: 3px;
    padding-left: 17px;
    border:1px solid #EEEEEE !important;
    color: $label_color;
    }
  .profileinput{
    background: white;
    padding: 20px;
    font-size: 12px;
    border-radius: 6px;
    padding-left: 14px;
    border: 1.5px solid #DADADB !important;
  }
  .select_input {
    border:1px solid $border_color !important;
    font-size: 14px !important;
    color: $label_color;
    border-radius: 3px;
  }
  .select_input_12 {
    border:1px solid #EEEEEE !important;
    font-size: 12px !important;
    color: $label_color;
  }
  .input_admin_color{
    color: $label_color !important;
  }
  .primaryColr{
    color: $primary_color;
  }
  .password_inupt_padding{
    padding-right: 40px;
  }
  .password_inupt_signup{
    padding-right: 150px;
  }
  .search_btn_advance {
    font-size: 13px;
    padding: 6.5px 15px;
  }
  .confirm_btn_modal{
    background-image: linear-gradient(
      270deg, #5239DC 0%, #9D38A4 100%);
        border: none;
        outline: none;
        color: #fafafa;
        padding: 9px 40px 6px 40px;
        border-radius: 3px;
        font-size: 10px;
  }
  .confirm_btn_modal:hover{
    color: #fafafa;
  }
  .cancel_modal_btn{
    outline: none;
    border:1px solid #534c4c;
    background-color: #ffffff;
    color: #534c4c;
    padding: 9px 30px 6px 30px;
    border-radius: 3px;
    font-size: 10px;
  }
  .cancel_modal_btn:hover{
    color: #534c4c;
    border:1px solid #534c4c;
    background-color: #ffffff;
  }
  .modal-inner-text{
    font-size: 14px;
    color: $primary_color;
    font-weight: 400;
  }
  @media (min-width: 1228px){
    .col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  @media only screen and (max-width: 1227px) and (min-width: 1200px){
    .col-xl-3 {
      -ms-flex: 0 0 33%;
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
  .sub_header{
    color: $primary_color;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 0.5px solid #AAAAAA;
    margin: 0;
    padding: 0;
  }
  .dragg_modal .modal-content {
    background-color:  transparent !important;
    border: none !important;
  }

  .Url_{
    color: $primary_color;
    text-decoration: underline;
    cursor: pointer;
  }

  .mat-expanded .mat-expansion-panel-header-title .material-icons{
    transform: rotate(180deg) !important;
  }
  .mat-expansion-panel-header-title .open,
.mat-expanded .mat-expansion-panel-header-title .close{
  display: inline-block !important;
}
.mat-expanded .mat-expansion-panel-header-title .open,
.mat-expansion-panel-header-title .close{
  display: none !important;
}

  .release_text{
    color: $label_color;
    font-size: 12px;
    margin-left: 5px;
    letter-spacing: 0.5px
  }

  .release_heading{
    color: $label_color !important;
    font-weight: bold !important;
    font-size: 14px;
  }
  .release_date{
    color: $label_color !important;
    font-size: 14px;
  }

.menuIcon {
  border: none !important;
}
.verticalAlignMid{
  vertical-align: middle;
}
.cancel_btn_modal_black {
  color: #000000;
  font-size: 18px;
    line-height: 20px;
    text-align: center;
    border-radius: 30px;
    position: absolute;
    right: 9px;
    top: 11px;
}
.disable_btn {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
  background-color: #c7c7c7 !important;
  color: #303030 !important;
  background-image: none;
}

.modal-backdrop {
  z-index: 1040 !important;
}
.modal-content {
  z-index: 1100 !important;
}

// Modal window

.modal-body {
  font-size: 12px !important;

  .header-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0;
    color: #fff !important;

    svg {
      cursor: pointer;
      margin: auto 0 auto auto;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      margin: auto auto auto 0;
    }
  }

  .input-section {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    min-height: 57px;

    label {
      width: 100%;
      word-break: break-all;
    }
  }

  .text-input {
    padding: 0.375rem 0.75rem;
  }

  input[type=text], input[type=number] {
    height: 32px;
    width: 100%;
    color: $label_color;

    &.disabled {
      background-color: #e9ecef;
    }
  }

  input {
    font-size: 12px !important;
    font-weight: 400;
    border-radius: 3px;
    border: 1px solid #ced4da;

    &.error {
      border-color: $error !important;
    }
  }

  input:focus {
    border-color: $primary_color;
  }

  .dialog-actions {
    display: flex;
    justify-content: right;
    margin: 0 !important;
    border-top: none !important;
    gap: 12px;

    .cancel-btn {
      border: 1px solid #979797;
      color: $label_color;
      border-radius: 3px;
    }

    button {
      font-size: 12px;
      margin: 5px;

      mat-spinner {
        margin: auto;
      }

      &:disabled, &.disabled {
        opacity: .5;
        pointer-events: none;
        background: #c7c7c7;
        border: 1px solid #c7c7c7;
        color: $label-color;
      }
    }
  }

  .dialog-divider {
    border: 1px solid #dddddd;
    margin: 1rem -1rem 1rem -1rem;
  }
}

.input-error {
  color: $error;
  font-size: 10px;
  margin-top: 10px;
}

.snap-button {
  outline: none;
  background: none;
  border: none;
  font-size: inherit;
  height: 100%;
  border-radius: 5px;
}

.tertiary {
  color: $primary_btn;
  text-underline-offset: 4px;

  &:hover {
    text-decoration: underline;
  }
}

.secondary {
  border: 1px solid $form-brd;

  &:hover {
    box-shadow: 0px 5px 10px 5px $shadow_color;
  }
}

.primary {
  background: $btn_color;
  color: $primary_text_color;

  &:hover {
    box-shadow: 0px 5px 10px 5px $shadow_color;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.primary-solid {
  background: $primary-color;
  color: $primary_text_color;

  &:hover {
    box-shadow: 0px 5px 10px 5px $shadow_color;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.mat-radio-label {
  margin-bottom: unset !important;
}

.speed-test-form {
  .mat-expansion-panel-body {
    padding-right: 0;
  }
}



// New UI CSSS

.errorinput {
  font-size: 10px;
  position: absolute;
  color: #DB1919;
}
.errorinput:first-letter {
    text-transform: capitalize;
}

.card{
  box-shadow: rgba(67, 16, 157, 0.2) 1px 1px 8px 2px;
  border-radius: 8px;
  // box-shadow: 0px 7px 29px 0px var($theme_color);
}

.compHeader{
  font-size: 18px;
  font-weight: bold;
  font-family: "ProximaNovaBold";
  display: flex;
  gap: 10px;
  align-items: center;

  span{
    font-size: 12px;
    font-family: "ProximaNova";
    color: #201F21;
    text-transform: capitalize;
  }
}
.listHeader{
  color: $theme_color;
}
.formHeaders{
  font-size: 16px;
  color: #fff;
  font-family: "ProximaNovaBold";
}

.cardName{
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: $theme_color;
  // height: 50px;
  justify-content: space-between;

  span{
      display: flex;
  align-items: center;
  }

  .imgSpan{
      background: $theme_img_background;
      height: 35px;
      width: 35px;
      border-radius: 25px;
      text-align: center;
      justify-content: center;
      margin-right: 8px;
      margin-left: 8px;
     
  
      img{
          width: 20px;
          object-fit: contain;
      }
    }
}


.cardData{
  align-items: center;
  display: flex;
  justify-content: space-between;
  // height: 50px;

  span:first-child{
      font-size: 20px;
      font-weight: bold;
  }
  span:nth-child(2){
      background: $theme_color;
      color: white;
      font-size: 12px;
      text-align: center;
      padding: 1px 6px;
      border-radius: 3px;

      img{
          width: 20px;
          object-fit: contain;
      }
  }
}

.cardHeader{
  font-size: 14px;
  color: $theme_color;
  font-weight: 600;
}
.cardSubHeader{
  font-size: 16px;
  font-weight: 600;
  color: $theme_textGrey;
}

.tableheading{
  white-space: nowrap;
}

.statusDiv{
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.statusDot{
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

.statusOk{
  background: $theme_started;
}
.statusError{
  background: $theme_error;
}
.statusWarning{
  background: $theme_warning !important;
}
.statusOkText{
  color: $theme_started !important;
}
.statusErrorText{
  color: $theme_error !important;
}
.statusWarningText{
  color: $theme_warning;
}
.statusDisabledText{
  color: $theme_disbaled !important;
}
.statusExpiredText{
  color: black;
}

.listFilters{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formsFilters{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $theme_color;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
}

.mat-form-field-infix{
  border-top-width: 0 !important;
  padding: 8px 5px !important;
}
.mat-form-field-flex{
  border: 1px solid rgb(99 99 99 / 30%) !important;
  font-size: 12px !important;
  color: $theme_textGrey;

}
.mat-form-field-wrapper{
  padding-bottom: 0 !important;
}
.mat-form-field-underline{
  display: none;
}
.matFormSelect{
  margin-left: 4px;
  height: 34px !important;

  .mat-form-field-infix{
    height: 32px;
    align-content: center;
  }

}
.search_input{
  border: 1px solid rgb(99 99 99 / 60%) !important;
  border-radius: 2px;
  font-size: 12px !important;
  padding: 8px 30px 8px 5px !important;
  width: 250px !important;
  height: 34px !important;
}
.form-control:focus{
  box-shadow: none !important;
}

.filterSpan{
  border: 1px solid $theme_color;
  object-fit: contain;
  align-content: center;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.filtersDiv{
  display: flex;
  align-items: center;
  gap: 10px;

  img{
    width: 16px;
    object-fit: contain;
    align-content: center;
    cursor: pointer;
  }
}

// ::-webkit-scrollbar {
//   width: 4px !important;
// }
// ::-webkit-scrollbar-thumb {
//   background: #fff !important ;
//   border-radius: 10px;
// }

.table-container {
  max-height: 65vh; /* Adjust as necessary */
  overflow-y: auto;
  position: relative;
}

.dataTable {
  width: 99%;
}
.w_97{
  width: 97% !important;
}

.dataTable th, .dataTable td {
  padding: 8px;
  text-align: left;
}
.dataTable td {
  background: #fff;
}

.enterTable thead tr:first-child th {
  position: sticky;
  top: 0;
  background-color: $table_darkTr !important;
  z-index: 10;
}
// .enterTable thead tr:first-child {
//   background-color: $table_darkTr;
// }
.enterTable thead tr:nth-child(2) th {
  position: sticky;
  top: 30px;
  background-color: $table_lightTr !important;
  z-index: 9;
}
// .enterTable thead tr:nth-child(2) {
//   background-color: $table_lightTr;
// }
.simpleTable thead tr:first-child th {
  position: sticky;
  top: 0px;
  background-color: $table_lightTr !important;
  z-index: 9;
}
.simpleTable thead tr:first-child {
  background-color: $table_lightTr;
}

/* Webkit-based browsers (Chrome, Safari, Opera) */
.table-container::-webkit-scrollbar {
width: 8px;
background-color: #e2e3e5;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.table-container::-webkit-scrollbar-thumb {
background-color: #fff;
width: 4px;
border-radius: 3px;
}

.table-container::-webkit-scrollbar-thumb:hover {
background-color: #fff;
}

/* Microsoft Edge and Internet Explorer */
.table-container::-ms-scrollbar {
width: 8px;
background-color: #e2e3e5;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.table-container::-ms-scrollbar-thumb {
background-color: #fff;
width: 4px;
border-radius: 3px;
}

.table-container::-ms-scrollbar-thumb:hover {
background-color: #fff;
}

.actionSpan{
display: flex;
gap: 14px;
padding-right: 15px;

img{
  width: 16px;
  object-fit: contain;
  cursor: pointer;
}
}

.addJustify{
  justify-content: space-between;
}


.switch {
position: relative;
display: inline-block;
width: 67px;
height: 15px;
margin: 0;
}

.switch input {
display: none;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgb(202 34 34 / 0.3);
-webkit-transition: .4s;
transition: .4s;
border-radius: 20px;
}

.slider:before {
position: absolute;
content: "";
height: 17px;
width: 17px;
left: -1px;
bottom: -1px;
background-color: #ca2222;
transition: 0.4s;
border-radius: 20px;
}

input:checked + .slider {
background-color: rgb(42 185 52 / 0.3);;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196F3;
}
.slider.round {
  border-radius: 20px;
  
}
input:checked + .slider:before {
-webkit-transform: translateX(55px);
-ms-transform: translateX(55px);
transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on {
display: none;
}

.on, .off {
color: #201F21 !important;
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
font-size: 12px;
font-weight: 600;
user-select:none;
font-family: "ProximaNova";
}
.off{
  margin-left: 5px !important;
}

input:checked + .slider .on {
display: block;
}
input:checked + .slider:before {
position: absolute;
content: "";
height: 17px;
width: 17px;
left: -1px;
bottom: -1px;
background-color: rgb(42 185 52);
transition: 0.4s;
}

input:checked + .slider .off {
display: none;
}

/*--------- END --------*/

/* Rounded sliders */


.higllightSpan{
background: rgb(67 16 157 / 10%);
padding: 1px 5px;
border-radius: 3px;
color: $theme_color;
}

.searchIcon {
position: absolute;
right: 9px;
top: 3px;
width: 26px !important;
width: 26px !important;
object-fit: contain;
}

.filterDrop{
position: relative; 
}
.detailDrop{
  position: absolute;
  z-index: 10;
  width: 218px;
  top: 36px;
  text-align: start;
  right: 0;

  .card{
    border-radius: 5px !important;
  }

.card-body{
  gap: 0px;
  display: flex;
  flex-direction: column;
}

label{
  margin: 0 !important;
}
}

.searchDrop{
left: 0;
top: 38px;
}

.filterButtons{
display: flex;
gap: 8px;
font-size: 12px;
justify-content: end;
margin-top: 5px;
}
.restoreFilter{
display: flex;
gap: 8px;
font-size: 12px;
justify-content: space-between;
margin-top: 5px;
}
.filterActionBtn {
border: 1px solid $theme_color;
padding: 0px 8px;
background: white;
color: $theme_color;
border-radius: 3px;
box-shadow: rgba(67 16 157 / 0.1) 0px 4px 3px 0px, rgba(67 16 157 / 0.05) 0px 4px 6px -2px;
cursor: pointer;
}
.formActionBtn {
border: 1px solid $theme_color;
padding: 4px 10px;
background: white;
color: $theme_color;
border-radius: 3px;
box-shadow: rgba(67 16 157 / 0.1) 0px 4px 3px 0px, rgba(67 16 157 / 0.05) 0px 4px 6px -2px;
cursor: pointer;
font-family: "ProximaNovaBold";
}
.nameFilter{
color: #fff;
background: $theme_color;
border: 1px solid $theme_color;
}

.listIcons{
width: 12px;
object-fit: contain;
}

.form-group .mat-form-field-flex {
border: none !important;
}
.form-group .mat-form-field{
padding: 1px 0px !important;
}

.addForm .form-group{
margin-bottom: 8px;

.form-control{
  border-radius: 1px !important;
  font-size: 12px !important;
  background-color: #ffffff;
}
}

.checked-checkbox .mat-checkbox-background {
background-color: $theme_color !important;
border: 1px solid $theme_color;
}

.unchecked-checkbox .mat-checkbox-background {
background-color: white;
border: 1px solid $theme_color;
}

.configureTh{
text-align: center !important;
width: 4%;
}
.custom-fixed-width {
  position: sticky;
  right: 14px;
  background-color: white; /* Or any other background color to match the table */
  z-index: 1;
  width: 60px !important;
  z-index: 0 
}
.count_config {
  color: #201F21 !important;
  font-weight: 400 !important;
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 10px;
  gap: 10px;
  color: #999999;
  margin-right: 28px;
  align-items: center;
  margin-top: 10px;
}
.pagination-buttons {
  display: flex;
}
.pagination-buttons .arrow-button img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.limit-select{
  color: #999999;
  font-size: 10px;
  border: none;
  font-weight: 600;
}
span.limit {
  align-items: center;
  line-height: 0;
  font-weight: 600;
}

.w_10{
  width: 10%;
}
.passwordSpan{
  width: 100%;
    display: flex;
    gap: 3px;
    align-items: center;

    // input {
    //   width: 55% !important;
    // }
    .generatePass{
      color: $theme_color;
      background: $theme_lightBack;
      font-size: 12px;
      padding: 7px 10px;
      width: 50%;
      border-radius: 4px;
      cursor: pointer;
      font-family: "ProximaNovaBold";
    }
    .eye{
      background: $theme_lightBack;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;
    }
}

.required{
  color: #DB1919;
}
.datepick .mat-datepicker-input{
  border: 1px solid #ced4db !important;
  padding-left: 10px !important;
  height: 35px !important;
  background-color: #fff;
  max-width: 97.5% !important;
}

.calendarImg{
  position: absolute;
  right: 13px;
  bottom: 8px;
  cursor: pointer;
}

.mat-dialog-container{
  padding-top: 14px !important;
  border-radius: 5px 5px 0 0 !important;
}

.backgroundTheme {
  z-index: 999;
  background-color: $theme_color !important;
}

th.configureTh.custom-fixed-width.backgroundTheme {
  z-index: 999;
  background-color: $theme_color !important;
}

.datepicker-toggle {
  position: absolute;
  top: 24px;
  right: 0px;
}
.enterCompDatePicker {
  top: 22px !important;
}

.custom-scrollbar {
    position: relative;
    height: calc(100vh - 335px);
    max-height: 55vh;
    overflow: hidden;
    background: #f5f5f5;
  }
  
  .custom-scrollbar-content {
    height: 100%;
    width: calc(100% + 17px);
    overflow-y: scroll;
    padding-right: 20px;
    box-sizing: content-box;
    background: #ffffff;
  }
  
  .custom-scrollbar-content::-webkit-scrollbar {
    display: none;
  }
  
  .custom-scrollbar-track {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 100%;
    background: #f1f1f1;
    border: 1px solid #fff;
    padding: 0 5px;
    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-right: 8px solid #fff;
  }
  
  .custom-scrollbar-thumb {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 8px rgba(67, 16, 157, 0.3);
    z-index: 99;
    
  }

  .showNone{
    opacity: 0;
    padding: 0;
  }

  .heartImg{
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
  }
  .crossForm{
    width: 18px;
    cursor: pointer;
  }
  
  .multiselect-item-checkbox input[type=checkbox]{
    opacity: 0 !important;
  }

  .dropExtraWidth{
    width: 280px;
  }

  .custom-snackbar-success {
    background-color: $snackbar_success !important;
    color: #fff !important;
  }
  .custom-snackbar-failure {
    background-color: $snackbar_failure !important;
    color: #fff !important;
  }

  .noDataClear{
    color: $theme_color;
    cursor: pointer;
  }

  .multiselect-dropdown .dropdown-btn{
    padding: 0 !important;
  }
  .nothingSelected .multiselect-dropdown .dropdown-btn{
    span{
        color: #000000 !important;
        padding: 0 !important;
      }
  }
 

  .mat-select-value{
    font-family: "ProximaNova" !important;
  }
  
  .multiselectFilter .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    right: 4px !important;
    top: 90% !important;
  }

  label{
    font-weight: 600;
  }
  .supportNames{
    background: $theme_color;
    padding: 3px;
    border-radius: 25px;
    color: #ffffff;
    cursor: pointer;
  }

  .empty-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    padding: 10%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    img{
        width: 20px;
    }
}

.empty-search-title {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 14px;
}
.modal-content{
  border: none !important;
}
.formLabel{
  color: #201F21;
}
.actionTd{
  padding-right: 28px !important;
}
.configurationList{
  max-height: 32vh;
  overflow-y: auto;
}
.disabled_page {
  pointer-events: none;
}
@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: $theme_color !important;
      color: #fff !important;
  }
}
.disabled {
  opacity: 0.6;
  background: $theme_btnDisbaled;
  pointer-events: none;
  border: 1px solid $theme_btnDisbaled;
  color: $theme_color;
}
.configureIcons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.left-side-container {
  .mat-calendar-body-cell-content {
    border-radius: unset !important;
  }

  .mat-calendar-body {
    tr[aria-hidden="true"] {
      display: none;
    }
  }
  .mat-calendar-body-label:first-of-type {
    opacity: 0;
  }
}

.devices-table, .left-side-container {
  .mat-checkbox-inner-container {
    width: 22px;
    height: 22px;
  }

  .mat-checkbox-checked .mat-checkbox-background {
    background-color: $primary-purple !important;
  }

  .mat-checkbox-frame {
    border-width: 1px;
    border-radius: unset;
    border-color: $checkbox-border;
  }
}

.new-button {
  font-size: 14px;
  font-weight: 700;
  padding: 5.5px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    opacity: 0.5;
    
    &:hover {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }
}

.new-secondary {
  color: $primary-purple;
  background-color: $white; 
  border: 1px solid $primary-purple;
  white-space: nowrap;
}

.new-primary {
  color: $white;
  border: 1px solid $primary-purple;
  background-color: $primary-purple;
}

.mat-input-element{
  box-sizing: border-box;
}

.checkbox{
  color: $theme_color;
}

.checkbox.mat-checkbox-checked .mat-checkbox-background,
.checkbox.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: $theme_color;
}

.form-control {
  color: #201f21;
}

.createDrop {
  position: absolute;
  z-index: 100;
  top: 46px;
  text-align: start;
  right: 20px;

  .card{
    border-radius: 5px !important;
  }

    .card-body {
        gap: 0px;
        display: flex;
        flex-direction: column;
        padding: 16px;
    }
    .card-body div {
        margin-bottom: 12px;
        border-bottom: 1px solid $table-border;
        padding-bottom: 6px;
        font-size: 12px;
        color: $theme_color;
        font-weight: 600;
        cursor: pointer;
    }

}

.noPointerEvents{
  pointer-events: none;
}

.sub-features .feature-list{
  width: 50% !important;
}

.subDropZone .preview img{
  object-fit: contain !important;
}

.singleEnterpriseHeader{
  position: relative;

  .detailDrop{
    left: 0 !important;
  }

  .dropExtraWidth{
    width: 400px;
  }
  .main-header {
    display: flex;
    flex-direction: row;
    padding: 1rem 20px 1rem 20px;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0,0,0,.1);

    div {
      font-size: 16px;
      font-weight: 500;
      margin: auto auto auto 0;
    }

    span {
      margin: auto 0 auto auto;
      svg {
        fill: $label_color;
        cursor: pointer;
      }
    }
  }

  .main-body {
    padding: 0 20px 10px 20px;
    max-height: 100%;
    width: 100%;

    .devices-info > div {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 5px 7px;
        margin: 0;
    }

    .devices-info > .custom_property_title {
      display: flex;
      justify-content: space-between;
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-right: 0;
      border-left: 0;
      margin: 20px 0 0 0;
    }
  }

  .infoName {
    font-size: 12px;
    font-family: "ProximaNovaBold";
  }
  .infoValue {
    font-size: 12px;
  }
}

.deviceListCheckbox{
  .mat-checkbox-layout{
    align-items: flex-start;
    margin-bottom: 10px !important;
  }

  .mat-checkbox-layout .mat-checkbox-label{
    line-height: 14px !important;
  }

  .mat-checkbox-inner-container{
    margin-top: 0 !important; 
  }
}