@import "./variable.scss";

.main-container {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	.chatbot-icon {
		position: absolute;
		bottom: 60px;
		right: 40px;
		border: 1px solid #dddddd;
		box-shadow: 2px 3px 3px #dddddd;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $full_page_background;
		z-index: 1;
		cursor: move;

		&.disabled {
			display: none;
		}
	}

	.chatbot-wrapper {
		position: absolute;
		bottom: 40px;
		right: 20px;
	}
} 

.mat-drawer-container{
	background: $bodycolor;
}


html .page-wrapper {
	background-color: $full_page_background;

	.page-content {
		display: flex;
		height: calc(100vh - 163px);
	}

	.headless {
		height: calc(100vh - 53px);
	}
}

.new-booking {
	background-color: $background-main !important;
}

.align-items-center{
		align-items: center;
}

.button-row button{
	margin-bottom: 5px;
    	margin-right: 5px;
}

.pl-5 {
	padding-left: 5px;
}
.pr-5 {
	padding-right: 5px;
}

// @media screen and (max-width: 1600px) {
// 	html .page-wrapper{
// 		.page-content{
// 			display: flex;
// 			height: calc(100vh - 119px);
// 		}
// 		.headless {
// 			height: calc(100vh - 37px);
// 		}
// 	}
// }

// @media screen and (max-width: 1024px) {
// 	html .page-wrapper{
// 		.page-content{
// 			display: flex;
// 			height: calc(100vh - 100px);
// 		}

// 		.headless {
// 			height: calc(100vh - 34px);
// 		}
// 	}
// }
