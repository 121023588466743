$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

/*Theme Colors*/

$bodycolor: #eef5f9;
$sidebar-icons: #99abb4;
$themecolor: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

// main variables
$full_page_background: #F5F5F5;    /* background color of full page content */

// SNAP VARIABLES
$primary_color: #43109d;
$primary_btn: #5939D7;
$btn_color1: #9D38A4;
$primary_text_color: #fafafa;
$grey_font: #9c9c9c;
$f_w_700: 700;
$btn_color: linear-gradient(270deg, #5239DC 0%, #9D38A4 100%);
$font_family: 'Poppins';

$primary-purple: #43109D;
$primary-purple-background: #43109D1A;

$col_EDF4FF: #EDF4FF;
$verizonTheme: #1D1C21;
$col_777777: #777777;
$label_color: #3A3A3A;
$error: #F72717;
$primary_success: #2BA327;
$primary_fail: #FF0000;
$primary_in_progress: #F0963B;
$primary_orange: #db754a;

// FONT FAMILY
$semibold_font: 'MontSemiBold';
$regular_font:"MontRegular";
$bold_font:"MontBold";

$radio-btn_color: linear-gradient(to right, #9838A5 10%, #622DE7 90%);
$font-color-grey: #9C9C9C;
$lightGrey: #F9F9F9;
$pearlLightGrey: #979797;
$almostGainsboroughGray: #DBDBDB;
$col_AAAAAA: #AAAAAA;
$col_7D7D7D: #7D7D7D;
$col_EEEEEE: #EEEEEE;
$col_FA7D00: #FA7D00;
$primary_5: rgba(94, 44, 237, 0.05);
$background_fail: #FF000010;
$shadow_color: #e5e5e5;
$primary-background: #43109d1a;
$col_F5F5F5: #F5F5F5;
$border_color: #ced4da;

$admin_brown: #ce7b5340;
$total_background: #F5F5F5;
$primary_color_background: #43109d0d;
$footer_background: #EDEDED;
$greyBorder: #EDEDED;

$primary_notification: #CE7B53;

//NEW THEME VARIABLE 

// colors

$theme_color : #43109D; // $primary-purple: #43109D; same but used on different places
$theme_hover : #CE7B53;
$theme_img_background: #F4F4F4;
$theme_textGrey: #4F4F4F;
$theme_started: #2BA327;
$theme_warning: #BD9E03;
$theme_error: #DB1919;
$theme_disbaled: #999999;
$table_darkTr: #E4E4E4;
$table_lightTr: #F5F5F5;
$dropdown_arrow: #999;
$theme_lightBack: #EDE8F6;
$snackbar_success: #2AA81F;
$snackbar_failure: #DB1919;
$theme_btnDisbaled: #D1C7E3;
$theme_bg_folderLight: #FAF6FF;
$theme_bg_form: #EFEFEF;
//fonts
.f400{
    font-weight: 400;
}

.table {
    th {
        vertical-align: bottom;
        background-color: rgb(99 99 99 / 30%);
        color: $theme_color;
        font-weight: bold;
        font-size: 12px;
      }
    td {
        font-size: 10px;
        font-weight: bold;
      }
}

.cursor{
  cursor: pointer;
}
.f_14{
  font-size: 14px;
}

// UI/UX revamp

$background-main: #D9D6D6;
$border-main: rgb(58,58,58,.15);
$font-black: #201F21;
$table-border: #D1D1D1;
$online-color: #2AA81F;
$offline-color: #DB1919;
$label-lighter: #4F4F4F;
$primary-orange: #CE7B53;
$input-text: #201F21;
$checkbox-border: #999999;
$subheader: #4F4F4F;
$light-grey-text: #F4F4F4;
$table-header: #f5f5f5;
$font-grey: #D1D1D1;
