@import './variable';

.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: 12px;
  font-family: inherit;
  height: 100%;

  div:first-child {
    height: inherit;
  }

  .dropdown-btn {
    border: 1px solid #ced4da !important;
    width: 100%;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.55 !important;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 1px !important;
    outline: none;
    color: #9C9C9C !important;
    font-size: inherit;
    padding: 0px 6px !important;
    height: 100%;
    display: flex !important;
    align-items: center;

    // padding: 6px 6px !important;
    // display: inline-block;

    span {
      color: $dropdown_arrow;
      padding-left: 6px;
      font-weight: normal;
      font-size: 12px;
    }

    .selected-item {
      border: 0 !important;
      margin-right: 0 !important;
      //background: transparent !important;
      //color: #303030 !important;
      border-radius: 2px;
      float: left;
      a {
        text-decoration: none;
        width: 0 !important;
      }
    }
    .dropdown-down {
      display: inline-block;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    .dropdown-up {
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  .disabled {
    & > span {
      background-color: #eceeef;
    }
  }
}

.dropdown-list {
  position: absolute;
  height: auto;
  width: 100%;
  z-index: 9999;
  border-radius: 3px;
  background: #fff;
  margin-top: 5px !important;
  box-shadow: none !important;

  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
  }
  li {
    padding: 0px 0px 0px 10px !important;
    cursor: pointer;
    text-align: left;
  }
  .filter-textbox {
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }
    input:focus {
      outline: none;
    }
  }
}

li.multiselect-item-checkbox {
  height: 35px !important;
  display: flex;
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: inherit !important;
  width: 1px !important;
  outline: none;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: transparent !important;
  background-color: transparent;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
  cursor: pointer;
  margin: auto 0 !important;
  color: #303030 !important;
  font-size: 12px !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  content: '';
  width: 14px !important;
  height: 14px !important;
  margin-top: -9px;
  border: 1px solid #ced4da !important;
  text-align: center;
  transition: all 0.4s ease;
  border-radius: 2px;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  content: '';
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
  border-radius: 3px;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
  border-color: #cccccc;
  border-radius: 3px;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:focus
+ div:before .multiselect-item-checkbox
input[type='checkbox']:disabled:hover + div:before {
  border-radius: 3px;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: transparent;
  width: 8px !important;
  left: 3px !important;
  top: 8px !important;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
  border-radius: 3px;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
  border-radius: 3px;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  transform: rotate(-45deg) scale(1);
  border-radius: 3px;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  position: relative;
  right: 10px !important;
  top: 90% !important;
  color: #999;
  margin-top: unset !important;
  border-style: solid;
  border-width: 5px 5px 0 !important;
  border-color: #999 transparent;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  width: 20px !important;
  height: 20px !important;
  transform: none !important;
}

h5 {
  font-size: inherit;
}

.mat-select-arrow{
  color: $dropdown_arrow !important;
}